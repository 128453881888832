import { Stack } from '@mui/material';
import { IeoCountdown, IeoPriceInfoTitle } from 'entities/crowdsale';
import { BORDER_RADIUS_L, COLOR_STROKE_SECONDARY } from 'shared';

import IEOActivateBg from '../../assets/crowdsale_bg.png';

export const IeoActivate = () => {
  return (
    <Stack
      position="relative"
      overflow="hidden"
      mt={5}
      py={{ xs: 8, md: 9.25 }}
      border={`1px solid ${COLOR_STROKE_SECONDARY}`}
      borderRadius={BORDER_RADIUS_L}
      p={{ xs: 2, sm: 4 }}
      alignItems="center"
      spacing={4}
      sx={{
        backgroundImage: `url(${IEOActivateBg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <IeoPriceInfoTitle />
      <IeoCountdown />
    </Stack>
  );
};
