import { Typography, TypographyProps } from '@mui/material';
import { INITIAL_EXCHANGE_OFFERING_END_TIME } from 'entities/crowdsale';
import { COLOR_ACCENT, Countdown } from 'shared';

export const IeoPriceInfoTitle = (props: TypographyProps) => {
  return (
    <Typography
      variant="h4"
      textTransform="uppercase"
      className="large semi-bold center"
      sx={{ span: { color: COLOR_ACCENT } }}
      {...props}
    >
      14-Day Window Before New CEX Listings!
      <br />@ $0.29
    </Typography>
  );
};

export const IeoCountdown = () => <Countdown endTime={INITIAL_EXCHANGE_OFFERING_END_TIME} />;
