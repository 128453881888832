import { Card, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { COLOR_ACCENT } from 'shared';
import { LAUNCHPADS } from 'shared/constants/launchpads/index';

const Container = styled(Card)(({ theme }) => ({
  marginTop: '1.5rem',
  padding: '2.5rem 1rem',
  display: 'grid',
  gridAutoRows: 'min-content',
  gap: '2rem',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    padding: '2.5rem 1rem 2.5rem 1rem',
  },
  [theme.breakpoints.up('md')]: {
    padding: '2.5rem 1rem 6rem 1rem',
  },
}));

const Title = styled(Typography)(() => ({
  '& span': {
    color: COLOR_ACCENT,
  },
}));

const StyledList = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '1.25rem 0.75rem',
  [theme.breakpoints.up('sm')]: {
    gap: '1.25rem 1rem',
  },
  [theme.breakpoints.up('lg')]: {
    gap: '2rem',
  },
}));

const ListItem = styled('li')(({ theme }) => ({
  display: 'contents',
  '& a': {
    display: 'grid',
    gridAutoRows: 'fit-content',
    justifyItems: 'center',
  },
  '& img': {
    height: '100%',
    maxHeight: '84px',
  },
  [theme.breakpoints.up('sm')]: {
    '& a': {
      gap: '1rem',
    },
    '& img': {
      maxHeight: '120px',
    },
  },
  [theme.breakpoints.up('md')]: {
    '& img': {
      maxHeight: '140px',
    },
    '& a': {
      width: '170px',
    },
  },
  [theme.breakpoints.up('lg')]: {
    '& a': {
      width: '200px',
    },
  },
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 'medium',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.25rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
}));

export const IdoLive = () => (
  <Container>
    <Title variant="h4" className="large semi-bold center">
      $CRAT <span>IDO NOW LIVE!</span>
    </Title>
    <StyledList>
      {LAUNCHPADS.map(({ image, title, url }) => (
        <ListItem key={`${title}--${url}`}>
          <a href={url} target="_blank" rel="noreferrer">
            <img src={image} alt="" />
            {/* @ts-expect-error - mui type issue */}
            <Label component="span">{title}</Label>
          </a>
        </ListItem>
      ))}
    </StyledList>
  </Container>
);
